<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('service_name.service_name') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
          <b-form  @submit.prevent="handleSubmit(searchData)" >
            <b-row>
              <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="Organization" vid="org_id" rules="">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="organization"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('org_pro.organization') }}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="organizationList"
                      id="org_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                   <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="Fee Name" vid="service_name">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="service_name"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('service_name.service_name')}}
                    </template>
                    <b-form-input
                      id="service_name"
                      v-model="search.service_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col class="col-sm-2">
                <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('service_name.service_name_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(organization)="data">
                      {{ getOrgName(data.item.org_id) }}
                    </template>
                    <template v-slot:cell(fee_name_bn)="data">
                      {{ getFeeName(data.item.fees) }}
                    </template>
                     <template v-slot:cell(fee_name_en)="data">
                      {{ getFeeName(data.item.fees) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status === 1 ? $t('globalTrans.active') : $t('globalTrans.inactive') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Active/Inactive" variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'
import FormV from './Form'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { serviceNameList, serviceToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import commonAuth from '../CommonAuth'

export default {
  mixins: [ModalBaseMasterList, commonAuth],
  components: {
    ValidationObserver,
    ValidationProvider,
    FormV
  },
  data () {
    return {
      search: {
        service_name: '',
        org_id: 0
      }
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('globalTrans.entry') : this.$t('globalTrans.modify')
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('globalTrans.organization'), class: 'text-left' },
        { label: this.$t('service_name.service_name'), class: 'text-left' },
        { label: this.$t('fee_name.fee_name'), class: 'text-left' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'organization' },
          { key: 'service_name_bn' },
          { key: 'fee_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'organization' },
          { key: 'service_name' },
          { key: 'fee_name_en' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    ...mapGetters({
      licenseCommonObj: 'licenseRegistration/commonObj'
    })
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
    this.search.org_id = this.userCheck()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(licenseRegistrationServiceBaseUrl, serviceToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, serviceNameList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getFeeName (fees) {
        const feeInfoList = this.licenseCommonObj.feeNamesList
        const feeList = fees.map(item => {
            const tmpFee = feeInfoList.find(fee => fee.value === item.fee_id)
            return this.$i18n.locale === 'en' ? tmpFee !== undefined ? tmpFee.text_en : '' : tmpFee !== undefined ? tmpFee.text_bn : ''
        })
        if (feeList === null || feeList === undefined) {
            return ''
        }
        return feeList.join(', ')
    },
    getOrgName (orgId) {
      const tmpOrg = this.organizationList.find(org => org.value === orgId)
      return this.$i18n.locale === 'en' ? tmpOrg !== undefined ? tmpOrg.text_en : '' : tmpOrg !== undefined ? tmpOrg.text_bn : ''
    }
  }
}
</script>
