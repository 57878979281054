<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="10" sm="12">
                      <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="organization"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="serviceName.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Service Name (En)" vid="service_name" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="service_name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('service_name.service_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="service_name"
                              v-model="serviceName.service_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Service Name (Bn)" vid="service_name_bn" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="service_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('service_name.service_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="service_name_bn"
                              v-model="serviceName.service_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Validity Period (Year)" vid="validity_period_yearly" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="validity_period_yearly"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('service_name.validity_period_yearly')}}
                          </template>
                          <b-form-select
                              plain
                              id="validity_period_yearly"
                              v-model="serviceName.validity_period_yearly"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <b-form-select-option value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            <b-form-select-option v-for="n in 10" :value="n" :key="n">{{ $n(n) }}</b-form-select-option>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Validity Period (Month)" vid="validity_period_monthly" rules="">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="validity_period_monthly"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('service_name.validity_period_monthly')}}
                          </template>
                          <b-form-select
                              plain
                              id="validity_period_monthly"
                              v-model="serviceName.validity_period_monthly"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <b-form-select-option value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            <b-form-select-option v-for="n in 12" :value="n" :key="n">{{ $n(n) }}</b-form-select-option>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Fee Info" vid="fee_id">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fee_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('service_name.fee_info')}}
                          </template>
                          <b-form-checkbox-group
                            v-model="serviceName.fee_id"
                            :options="feeNameList"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-checkbox-group>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                     </b-row>
                       <template>
                        <div style="background-color: #66cc99;margin-bottom: 5px; text-align:center">
                          <h5 class="text-white pl-2"> {{ $t('sidebar.accessControl.permission') }} </h5>
                        </div>
                      </template>
                      <b-row>
                        <b-col xs="12" sm="12" md="3" lg="3" class="mt-3" offset="1">
                          <ValidationProvider>
                            <b-form-group
                            class="row"
                            label-cols-sm="6"
                            label-for="public_application"
                            slot-scope="{ errors }"
                            >
                            <template v-slot:label>
                              {{ $t('service_name.public_application') }}
                            </template>
                            <b-form-checkbox
                            class="mt-2"
                            id="public_application"
                            v-model="serviceName.public_application"
                            ></b-form-checkbox>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" class="mt-3" offset="1">
                          <ValidationProvider>
                            <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="renew"
                            slot-scope="{ errors }"
                            >
                            <template v-slot:label>
                              {{ $t('component_settings.renew') }}
                            </template>
                            <b-form-checkbox
                            class="mt-2"
                            id="renew"
                            v-model="serviceName.renew"
                            ></b-form-checkbox>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" class="mt-3" offset="1">
                          <ValidationProvider>
                            <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="lost"
                            slot-scope="{ errors }"
                            >
                            <template v-slot:label>
                              {{ $t('component_settings.lost') }}
                            </template>
                            <b-form-checkbox
                            class="mt-2"
                            id="lost"
                            v-model="serviceName.lost"
                            ></b-form-checkbox>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" class="mt-3" offset="1">
                          <ValidationProvider>
                            <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="choose_payment"
                            slot-scope="{ errors }"
                            >
                            <template v-slot:label>
                              {{ $t('component_settings.choose_payment') }}
                            </template>
                            <b-form-checkbox
                            class="mt-2"
                            id="choose_payment"
                            v-model="serviceName.choose_payment"
                            ></b-form-checkbox>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" offset="1">
                          <ValidationProvider>
                            <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="transfer"
                            slot-scope="{ errors }"
                            >
                            <template v-slot:label>
                              {{ $t('component_settings.transfer') }}
                            </template>
                            <b-form-checkbox
                            class="mt-2"
                            id="transfer"
                            v-model="serviceName.transfer"
                            ></b-form-checkbox>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" offset="1">
                          <ValidationProvider>
                            <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="withdraw"
                            slot-scope="{ errors }"
                            >
                            <template v-slot:label>
                            {{ $t('component_settings.withdraw') }}
                            </template>
                            <b-form-checkbox
                            class="mt-2"
                            id="withdraw"
                            v-model="serviceName.withdraw"
                            ></b-form-checkbox>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" offset="1">
                          <ValidationProvider>
                            <b-form-group
                            class="row"
                            label-cols-sm="5"
                            label-for="cancel"
                            slot-scope="{ errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.cancel') }}
                            </template>
                            <b-form-checkbox
                            class="mt-2"
                            id="cancel"
                            v-model="serviceName.cancel"
                            ></b-form-checkbox>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <template>
                        <div style="background-color: #66cc99;margin-bottom: 5px; text-align:center">
                          <h5 class="text-white pl-2"> {{ $t('component_settings.office_info') }} </h5>
                        </div>
                      </template>
                      <b-col xs="12" sm="12" md="6" lg="6" class="mt-3" offset="3">
                        <ValidationProvider name="custom_office" vid="custom_office">
                          <b-form-group
                            class="row"
                            label-cols-sm="6"
                            label-for="custom_office"
                          >
                            <template v-slot:label>
                              {{$t('component_settings.fixed_office')}}
                            </template>
                            <b-form-checkbox
                              class="mt-2"
                              id="custom_office"
                              v-model="serviceName.custom_office"
                            >
                            </b-form-checkbox>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-row v-if="serviceName.custom_office">
                        <b-col xs="12" sm="12" md="6" lg="6">
                          <ValidationProvider name="Office Type" vid="office_type_id">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="office_type_id"
                            >
                            <template v-slot:label>
                              {{$t('seedsSeeds.office_type')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="serviceName.office_type_id"
                            :options="officeTypeList"
                            id="office_type_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6">
                          <ValidationProvider name="Office Name" vid="office_name_id">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="office_name_id"
                            >
                            <template v-slot:label>
                              {{$t('seedsSeeds.office_name')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="serviceName.office_name_id"
                            :options="officeList"
                            id="office_name_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                        <template>
                          <div style="background-color: #66cc99;margin-bottom: 5px; text-align:center">
                            <h5 class="text-white pl-2"> {{ $t('globalTrans.office_cat') }} </h5>
                          </div>
                      </template>
                      <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6">
                          <ValidationProvider name="office_category_check" vid="office_category_check">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="office_category_check"
                            >
                            <template v-slot:label>
                              {{$t('globalTrans.office_cat')}}
                            </template>
                            <b-form-checkbox
                              class="mt-2"
                              id="office_category_check"
                              v-model="serviceName.office_category_check"
                            >
                            </b-form-checkbox>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6">
                          <ValidationProvider name="regional_office_check" vid="regional_office_check">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="regional_office_check"
                            >
                            <template v-slot:label>
                              {{$t('org_pro.is_regional_office')}}
                            </template>
                            <b-form-checkbox
                              class="mt-2"
                              id="regional_office_check"
                              v-model="serviceName.regional_office_check"
                            >
                            </b-form-checkbox>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { serviceNameStore, serviceNameUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  props: ['id'],
  mixins: [commonAuth],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      serviceName: {
        org_id: 0,
        fee_id: [],
        service_name: '',
        service_name_bn: '',
        validity_period_yearly: 0,
        validity_period_monthly: 0,
        public_application: false,
        office_type_id: 0,
        office_name_id: 0,
        office_category_check: '',
        regional_office_check: '',
        choose_payment: 0
      },
      loading: false,
      officeList: [],
      officeTypeList: []
    }
  },
   watch: {
    'serviceName.org_id': function (newVal, oldVal) {
      if (newVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'serviceName.office_type_id': function (newVal, oldVal) {
      if (newVal) {
        this.officeList = this.getOfficeList(newVal)
      }
    }
  },
  created () {
    this.serviceName.org_id = this.userCheck()
    if (this.id) {
      const tmp = this.getServiceData()
      let custom
      if (tmp.office_type_id) {
         custom = true
      } else {
        custom = false
      }
      this.serviceName = Object.assign(tmp, { custom_office: custom })
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    feeNameList: function () {
            const feeDataList = this.$store.state.licenseRegistration.commonObj.feeNamesList.filter(item => item.status === 1)
            return feeDataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
    },
    ...mapGetters({
      licenseCommonObj: 'licenseRegistration/commonObj'
    })
  },
  methods: {
    getServiceData () {
      let tmpData = this.$store.state.list.find(item => item.id === this.id)
      const isApplicationFee = Boolean(tmpData.public_application)
      const isWithDraw = Boolean(tmpData.withdraw)
      const isCancel = Boolean(tmpData.cancel)
      const isTransfer = Boolean(tmpData.transfer)
      const isRenew = Boolean(tmpData.renew)
      const isLost = Boolean(tmpData.lost)
      const isChoosePayment = Boolean(tmpData.choose_payment)
      const isOfficeCategoryCheck = Boolean(tmpData.office_category_check)
      const isRegionalOfficeCheck = Boolean(tmpData.regional_office_check)
      tmpData = Object.assign({}, tmpData, {
        public_application: isApplicationFee,
        withdraw: isWithDraw,
        cancel: isCancel,
        transfer: isTransfer,
        renew: isRenew,
        lost: isLost,
        choose_payment: isChoosePayment,
        office_category_check: isOfficeCategoryCheck,
        regional_office_check: isRegionalOfficeCheck,
        fee_id: tmpData.fees.map(ut => ut.fee_id)
      })
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.serviceName.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${serviceNameUpdate}/${this.id}`, this.serviceName)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, serviceNameStore, this.serviceName)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
      this.loading = false
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getOfficeTypeList (orgID) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === orgID)
      return officeTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    }
  }
}
</script>
